import { BaseComponent } from 'utils/base-component'

import './leaflet.scss'
import './static/placeholder-icon.svg'
import './static/placeholder.jpg'

export class Leaflet extends BaseComponent {
  static cssClass = 'leaflet'

  static init(parent, data) {
    const css = this.cssClass
    Array.from(parent.querySelectorAll(`.${css}`)).map(el => {
      const { mapId } = el.dataset
      return data[mapId] ? new this(el, css, data[mapId]) : null
    })
  }

  constructor(root, cssClass, data) {
    super(root, cssClass)
    this.data = data
  }

  init() {
    this.element('pulse-animation').toggleModifier('loading')

    const { loadingStrategy } = this.root.dataset

    if (loadingStrategy === 'pointer-over-container') {
      this.pointerOverListener = this.loadMap.bind(this)
      this.root.addEventListener('pointerover', this.pointerOverListener)
    } else if (loadingStrategy === 'pointer-over-document') {
      this.pointerOverListener = this.loadMap.bind(this)
      document.body.addEventListener('pointerover', this.pointerOverListener)
    } else if (loadingStrategy === 'instant') {
      this.chunk()
    }
  }

  loadMap() {
    document.body.removeEventListener('pointerover', this.pointerOverListener)
    this.root.removeEventListener('pointerover', this.pointerOverListener)
    this.chunk()
  }

  chunk() {
    this.element('pulse-animation').toggleModifier('loading')

    import(/* webpackChunkName: 'leaflet' */ './map').then(({ Map }) => {
      const map = new Map(this.root, this.data)
      map.init()

      const placeholder = this.element('placeholder')
      if (placeholder) placeholder.element.remove()
    })
  }
}
