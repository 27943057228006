import Tooltip from './popper'
import './information.scss'
import './hint.scss'

export default function (container = document) {
  Array.from(container.querySelectorAll('.tooltips')).forEach(el => {
    const type = el.classList.contains('info') ? 'info' : 'hint'
    const tooltip = new Tooltip(el, type)
    tooltip.init()
  })
}
