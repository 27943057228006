/* eslint-disable no-underscore-dangle */
import { ScrollButtonState } from './state'

export class ScrollButtonBloc {
  /**
   * Creates an instance of ScrollButtonBloc.
   * @param {object} settings
   * @param {function(ScrollButtonState): void} settings.build
   * @param {import('./repository').ScrollButtonRepository} settings.scrollButtonRepository
   * @memberof ScrollButtonBloc
   */
  constructor({ build, scrollButtonRepository }) {
    this._build = build
    this._scrollButtonRepository = scrollButtonRepository

    this.state = new ScrollButtonState({ isHidden: false })

    this._onClickCallbackList = []
    this._onLongPressCallbackList = []
  }

  /**
   * @param {ScrollButtonState} newState
   * @memberof ScrollButtonBloc
   */
  update(newState) {
    if (newState === this.state) return

    this._build(newState)
    this.state = newState
  }

  /**
   * @param {boolean} shouldHide
   * @memberof ScrollButtonBloc
   */
  toggleVisibility(shouldHide) {
    this.update(this.state.copyWith({ isHidden: shouldHide }))
  }

  buttonMouseDown() {
    this._scrollButtonRepository.onNextFrames((deltaTime, tense) =>
      this._onLongPressCallbackList.forEach(cb => cb(deltaTime, tense))
    )
  }

  buttonMouseUp() {
    this._scrollButtonRepository.removeListener()
    this._onClickCallbackList.forEach(cb => cb())
  }

  onClick(cb) {
    this._onClickCallbackList.push(cb)
  }

  onLongPress(cb) {
    this._onLongPressCallbackList.push(cb)
  }
}
