/* eslint-disable no-underscore-dangle */
export class ScrollButtonRepository {
  /**
   * Creates an instance of ScrollButtonRepository.
   * @param {import('./frames-service').FramesService} frameService
   * @memberof ScrollButtonRepository
   */
  constructor(frameService) {
    this._frameService = frameService
  }

  /**
   * Listen to every frame
   * @param {function(number, number): void} cb
   * @memberof ScrollButtonRepository
   */
  onNextFrames(cb) {
    this._frameService.start(cb)
  }

  removeListener() {
    this._frameService.stop()
  }
}
