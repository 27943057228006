import './tshirts.scss'

function enlargeImage(e) {
  const zoomIn = e.currentTarget
  const offsetX = e.offsetX !== undefined ? e.offsetX : e.touches[0].pageX
  const offsetY = e.offsetY !== undefined ? e.offsetY : e.touches[0].pageX
  const x = (offsetX / zoomIn.offsetWidth) * 100
  const y = (offsetY / zoomIn.offsetHeight) * 100
  zoomIn.style.backgroundPosition = `${x}% ${y}%`
}
export default function () {
  if (window.matchMedia('(max-width: 950px)').matches === false) {
    Array.from(document.querySelectorAll('figure.tshirts__zoom')).forEach(el =>
      el.addEventListener('mousemove', enlargeImage)
    )
  }
}
