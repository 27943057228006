import { createPopperLite as createPopper, preventOverflow, flip } from '@popperjs/core'

import chill from './static/chill.jpg'
import snowfall from './static/snowfall.jpg'
import rainfall from './static/rainfall.jpg'
import freezinglevel from './static/freezinglevel.jpg'

const dict = {
  chill,
  snowfall,
  rainfall,
  freezinglevel
}

export default class Tooltip {
  constructor(el, type) {
    this.popper = null
    this.el = el
    this.type = type
  }

  init() {
    this.el.addEventListener('click', e => {
      e.preventDefault()
      this.show()
    })

    this.el.addEventListener('mouseenter', e => {
      e.preventDefault()
      this.show()
    })

    this.el.addEventListener('mouseleave', e => {
      e.preventDefault()
      this.hide()
    })
  }

  show() {
    if (this.popper) return
    this.renderTooltip()
    document.body.appendChild(this.tooltip)

    this.popper = createPopper(this.el, this.tooltip, {
      placement: this.type === 'info' ? 'bottom-start' : 'top',
      modifiers: [
        flip,
        preventOverflow,
        {
          name: 'preventOverflow',
          options: {
            padding: {
              top: 16,
              right: 16,
              bottom: 16,
              left: 16
            }
          }
        }
      ]
    })

    this.closeHandler = e => {
      if (
        this.tooltip !== e.target &&
        !this.tooltip.contains(e.target) &&
        this.el !== e.target &&
        !this.el.contains(e.target)
      ) {
        this.hide()
      }
    }
    window.addEventListener('click', this.closeHandler)
    this.closeButton.addEventListener('click', e => {
      e.preventDefault()
      this.hide()
    })
  }

  hide() {
    if (this.popper) {
      this.popper.destroy()
      this.popper = null
    }

    window.removeEventListener('click', this.closeHandler)
    this.tooltip.parentNode.removeChild(this.tooltip)
  }

  renderTooltip() {
    const bemBlock = this.type === 'info' ? 'information-tooltips__' : 'hint-tooltips__'
    this.tooltip = document.createElement('div')
    this.tooltip.classList.add(`${bemBlock}tooltip-popup`)

    if (this.el.dataset.tooltipTitle) {
      const tooltipHeader = document.createElement('div')
      tooltipHeader.classList.add(`${bemBlock}tooltip-popup__header`)
      this.tooltip.appendChild(tooltipHeader)
      tooltipHeader.innerText = this.el.dataset.tooltipTitle || ''
    }

    if (this.el.dataset.tooltipImg) {
      const tooltipImg = document.createElement('div')
      tooltipImg.classList.add(`${bemBlock}tooltip-popup__body--has-image`)
      tooltipImg.style.backgroundImage = `url('${dict[this.el.dataset.tooltipImg]}')`
      this.tooltip.appendChild(tooltipImg)
    }

    const tooltipBody = document.createElement('div')
    tooltipBody.classList.add(`${bemBlock}tooltip-popup__body`)
    this.tooltip.appendChild(tooltipBody)

    if (this.type === 'hint') {
      const tooltipArrow = document.createElement('div')
      tooltipArrow.classList.add(`${bemBlock}arrow`)
      tooltipArrow.setAttribute('data-popper-arrow', '')
      this.tooltip.appendChild(tooltipArrow)
    }

    if (this.el.dataset.tooltipText) {
      tooltipBody.innerHTML = this.el.dataset.tooltipText
    }

    if (this.type === 'info') {
      this.closeButton = document.createElement('div')
      this.closeButton.classList.add(`${bemBlock}tooltip-popup__close-button`)
      this.tooltip.appendChild(this.closeButton)
    }
  }
}
