import arrowUp from 'static/icons/icon-arrow-up.svg'
import arrowDown from 'static/icons/icon-arrow-down.svg'

const toggleClass = (el, { opened }) => {
  if (!el) return null

  if (opened) return el.classList.remove('is-truncated')

  return el.classList.add('is-truncated')
}

const toggleCaret = (e, settings) => {
  if (!e) return null

  if (settings.opened) {
    e.src = arrowUp
    return e
  }

  e.src = arrowDown
  return e
}

const click = (ct, settings) => {
  let opened = settings.opened || false

  return function f(e) {
    e.preventDefault()

    opened = !opened

    ct.forEach(el => toggleClass(el, { opened }))
    toggleCaret(this.querySelector('[data-read-more-caret]'), { opened })
    this.dataset.opened = opened
  }
}

export default function (el) {
  if (!el) return null

  const content = Array.from(document.querySelectorAll(el.dataset.for))

  return el.addEventListener('click', click(content, { opened: el.dataset.opened === 'true' }))
}
