import { BaseComponent, BElement } from 'utils/base-component'
import './index.scss'
import './static/icon-close.svg'

export class Smartbar extends BaseComponent {
  static cssClass = 'smartbar'

  private close!: BElement
  private CLOSEDCLASS: string

  init() {
    this.close = this.element('close-button')

    this.CLOSEDCLASS = `${Smartbar.cssClass}--closed`

    if (document.cookie.indexOf('smartbarClosed=1') !== -1) {
      this.root.classList.add(this.CLOSEDCLASS)
      document.documentElement.style.paddingTop = '0'
    }

    this.close.element.addEventListener('click', (e) => {
      e.preventDefault()

      const expiryDate = new Date()
      expiryDate.setMonth(expiryDate.getMonth() + 1)

      document.cookie = `smartbarClosed=1; expires=${expiryDate.toUTCString()}; path=/`

      this.root.classList.add(this.CLOSEDCLASS)
      document.documentElement.style.paddingTop = '0'
    })
  }
}
