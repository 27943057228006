import './gallery_sort_links.scss'

export default function () {
  const className = 'gallery-sort-links'

  Array.from(document.querySelectorAll(`.${className}`)).forEach(el => {
    const selected = el.querySelector(`.${className}__selected`)
    const options = el.querySelector(`.${className}__options`)
    let visible = false

    selected.addEventListener('click', e => {
      e.preventDefault()
      options.classList.toggle(`${className}__options--visible`)
      visible = !visible
    })

    window.addEventListener('click', e => {
      if (visible && el !== e.target && !el.contains(e.target)) {
        options.classList.remove(`${className}__options--visible`)
        visible = !visible
      }
    })
  })
}
