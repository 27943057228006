/* eslint-disable no-underscore-dangle */
import fastdom from 'fastdom'

import { BaseComponent } from 'utils/base-component'

import { ScrollButtonBloc } from './js/bloc'
import { FramesService } from './js/frames-service'
import { ScrollButtonRepository } from './js/repository'
import { ScrollButtonView } from './js/view'
import './scroll-button.scss'

export class ScrollButton extends BaseComponent {
  static cssClass = 'scroll-button'

  init() {
    this.button = this.element('button')
    this.direction = this.button.hasModifier('is-left') ? 'left' : 'right'

    this._scrollButtonRepository = new ScrollButtonRepository(new FramesService())
    this._bloc = new ScrollButtonBloc({
      build: state => this.build(state),
      scrollButtonRepository: this._scrollButtonRepository
    })
    this._view = new ScrollButtonView(this._bloc, this.button)
  }

  build(state) {
    fastdom.mutate(() => {
      this._view.update(state)
    })
  }

  toggleVisibility(shouldHide) {
    this._bloc.toggleVisibility(shouldHide)
  }

  onClick(cb) {
    this._bloc.onClick(cb)
  }

  onLongPress(cb) {
    this._bloc.onLongPress(cb)
  }
}
