export class FramesService {
  constructor() {
    this.isRunning = false
  }
  /**
   * @param {function(number, number): void} cb
   * @memberof FramesService
   */
  start(cb) {
    this.isRunning = true

    let startTime
    let lastTime

    /**
     * @param {number} timestamp
     */
    const frameRequestCallback = timestamp => {
      if (startTime === undefined) startTime = timestamp
      if (lastTime === undefined) lastTime = timestamp

      const duration = timestamp - startTime
      const tense = Math.max(Number((duration / 1000).toFixed(2)), 1)
      const deltaTime = timestamp - lastTime
      cb(deltaTime, tense)

      lastTime = timestamp
      if (this.isRunning) requestAnimationFrame(frameRequestCallback)
    }

    requestAnimationFrame(frameRequestCallback)
  }

  stop() {
    this.isRunning = false
  }
}
