export class ScrollContainerState {
  /**
   * Creates an instance of ScrollContainerState.
   * @param {object} settings
   * @param {number} settings.scrollX
   * @param {boolean} settings.isLeftButtonHidden
   * @param {boolean} settings.isRightButtonHidden
   * @memberof ScrollContainerState
   */
  constructor({ scrollX, isLeftButtonHidden, isRightButtonHidden }) {
    this.scrollX = scrollX
    this.isLeftButtonHidden = isLeftButtonHidden
    this.isRightButtonHidden = isRightButtonHidden
  }

  /**
   * @param {object} settings
   * @param {number} settings.scrollX
   * @param {boolean} settings.isLeftButtonHidden
   * @param {boolean} settings.isRightButtonHidden
   * @returns {ScrollContainerState}
   * @memberof ScrollContainerState
   */
  copyWith({ scrollX, isLeftButtonHidden, isRightButtonHidden }) {
    return new ScrollContainerState({
      scrollX: scrollX === undefined ? this.scrollX : scrollX,
      isLeftButtonHidden:
        isLeftButtonHidden === undefined ? this.isLeftButtonHidden : isLeftButtonHidden,
      isRightButtonHidden:
        isRightButtonHidden === undefined ? this.isRightButtonHidden : isRightButtonHidden
    })
  }
}
