/* eslint-disable no-underscore-dangle */

/**
 * @param {function(): void} cb
 * @param {MouseEvent} event
 */
function mouseUpListenerOnce(cb, event) {
  event.preventDefault()

  cb()
  this.removeEventListener('mouseup', mouseUpListenerOnce)
}

export class ScrollButtonView {
  /**
   * Creates an instance of ScrollButtonView.
   * @param {import('./bloc').ScrollButtonBloc} context
   * @param {import('utils/base-component').BElement} button
   * @memberof ScrollButtonView
   */
  constructor(context, button) {
    this._context = context

    this.button = button

    this.button.element.addEventListener('mousedown', event => {
      event.preventDefault()
      context.buttonMouseDown()

      document.addEventListener(
        'mouseup',
        mouseUpListenerOnce.bind(document, () => context.buttonMouseUp())
      )
    })
  }

  /**
   * @param {import('./state').ScrollButtonState} state
   * @memberof ScrollButtonView
   */
  update(state) {
    this.button.toggleModifier('is-hidden', state.isHidden)
  }
}
