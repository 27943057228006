/* eslint-disable no-underscore-dangle */

import throttle from 'utils/throttle'
import { BaseComponent } from 'utils/base-component'
import { ScrollButton } from 'components/scroll-button'

import { ScrollContainerState } from './state'
import './scroll-container.scss'

export class ScrollContainer extends BaseComponent {
  static cssClass = 'scroll-container'
  static velocity = 0.5

  init() {
    this.scrollButtons = ScrollButton.init(this.root)

    this.state = new ScrollContainerState({
      scrollX: this.root.scrollLeft,
      isLeftButtonHidden: !this._hasScrollSpaceOnLeft(),
      isRightButtonHidden: !this._hasScrollSpaceOnRight()
    })

    this.root.addEventListener(
      'scroll',
      throttle(() => {
        this.state = this.state.copyWith({ scrollX: this.root.scrollLeft })

        this.update(
          this.state.copyWith({
            isLeftButtonHidden: !this._hasScrollSpaceOnLeft(),
            isRightButtonHidden: !this._hasScrollSpaceOnRight()
          })
        )
      }, 100)
    )

    new MutationObserver(() => {
      this.update(
        this.state.copyWith({
          isLeftButtonHidden: !this._hasScrollSpaceOnLeft(),
          isRightButtonHidden: !this._hasScrollSpaceOnRight()
        })
      )
    }).observe(this.root, {
      childList: true,
      subtree: true
    })

    this.scrollButtons.forEach(scrollButton => {
      scrollButton.onLongPress((deltaTime, tense) => {
        let scrollX
        if (scrollButton.direction === 'left') {
          scrollX = this.state.scrollX - deltaTime * ScrollContainer.velocity * tense
        } else {
          scrollX = this.state.scrollX + deltaTime * ScrollContainer.velocity * tense
        }

        const { offsetWidth, scrollWidth } = this.root

        this.update(
          this.state.copyWith({
            scrollX: Math.min(Math.max(0, scrollX), scrollWidth - offsetWidth)
          })
        )
      })
    })

    this.build(this.state)
  }

  update(newState) {
    if (this.state === newState) return

    this.build(newState)
    this.state = newState
  }

  build(state) {
    this.root.scrollLeft = state.scrollX

    this.scrollButtons.forEach(scrollButton => {
      if (scrollButton.direction === 'left') {
        scrollButton.toggleVisibility(state.isLeftButtonHidden)
      }

      if (scrollButton.direction === 'right') {
        scrollButton.toggleVisibility(state.isRightButtonHidden)
      }
    })
  }

  _hasScrollSpaceOnRight() {
    const { offsetWidth, scrollWidth, scrollLeft } = this.root
    return scrollWidth > offsetWidth + scrollLeft
  }

  _hasScrollSpaceOnLeft() {
    const { scrollLeft } = this.root
    return scrollLeft > 0
  }
}
