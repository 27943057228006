/* eslint-disable no-underscore-dangle */
import { createPopper } from '@popperjs/core'

import airbrake from 'utils/airbrake'
import { BaseComponent } from 'utils/base-component'

import mountainTop from './static/top.svg'
import mountainMid from './static/mid.svg'
import mountainBot from './static/bot.svg'
import './static/mountain-outline.png'
import './forecast-table-elevation.scss'

/** @type {Object<string, string>} */
const images = {
  top: mountainTop,
  mid: mountainMid,
  bot: mountainBot
}

const colors = {
  top: 'hsl(201, 20%, 23%)',
  mid: 'hsl(165, 19%, 40%)',
  bot: 'hsl(133, 18%, 59%)'
}

class State {
  /**
   * Creates an instance of State.
   * @param {object} payload
   * @param {boolean} payload.dropdownMenuShown
   * @param {string} payload.dropdownPrefix
   * @param {number} payload.elevation
   * @param {string} payload.image
   * @param {string} payload.color
   * @memberof State
   */
  constructor({ dropdownMenuShown, dropdownPrefix, elevation, image, color }) {
    this.dropdownMenuShown = dropdownMenuShown
    this.dropdownPrefix = dropdownPrefix
    this.elevation = elevation
    this.image = image
    this.color = color
  }

  /**
   * @param {object} payload
   * @param {boolean?} payload.dropdownMenuShown
   * @param {string?} payload.dropdownPrefix
   * @param {number?} payload.elevation
   * @param {string?} payload.image
   * @param {string?} payload.color
   * @memberof State
   */
  copyWith({ dropdownMenuShown, dropdownPrefix, elevation, image, color }) {
    return new State({
      dropdownMenuShown:
        dropdownMenuShown === undefined ? this.dropdownMenuShown : dropdownMenuShown,
      dropdownPrefix: dropdownPrefix === undefined ? this.dropdownPrefix : dropdownPrefix,
      elevation: elevation === undefined ? this.elevation : elevation,
      image: image === undefined ? this.image : image,
      color: color === undefined ? this.color : color
    })
  }
}

export class ForecastTableElevation extends BaseComponent {
  static cssClass = 'forecast-table-elevation'

  init() {
    this.dropdown = this.element('dropdown')
    this.list = this.element('list-wrapper')

    const linkList = this.elements('link')
    const activeLink = linkList.find(link => link.hasModifier('is-active'))

    let elevationLevel
    let elevationGroup
    let isSingleElevation = false
    if (activeLink == null) {
      isSingleElevation = true
      const singleElevation = this.element('single-elevation')
      ;({ elevationLevel, elevationGroup } = singleElevation.element.dataset)
    } else {
      ;({ elevationLevel, elevationGroup } = activeLink.element.dataset)
    }

    let dropdownPrefix = ''
    if (!isSingleElevation) {
      const prefix = activeLink.element.querySelector(`.${this.elementClassName('prefix')}`)
      if (prefix != null) dropdownPrefix = prefix.dataset.elevationPrefix
    }

    /** @type {State} */
    this.state = new State({
      dropdownMenuShown: !isSingleElevation && this.list.hasModifier('is-popup'),
      elevation: Number(elevationLevel),
      image: images[elevationGroup],
      color: colors[elevationGroup],
      dropdownPrefix
    })

    /** @type {import('@popperjs/core').Instance?} */
    this.popper = null
    /** @type {(event: MouseEvent) => void} */
    this.clickListener = this._outsideClickListenerOnce.bind(this)

    if (!isSingleElevation) {
      this.dropdown.element.addEventListener('click', () => {
        this.update(
          this.state.copyWith({
            dropdownMenuShown: !this.state.dropdownMenuShown
          })
        )
      })
    }

    if (activeLink != null) this.build(this.state)

    linkList.forEach(link => {
      const el = link.element
      el.addEventListener('click', async e => {
        e.preventDefault()
        link.toggleModifier('loading', true)
        await this.linkChanged(el)
        linkList.forEach(l => l.toggleModifier('is-active', false))
        link.toggleModifier('is-active', true)
        link.toggleModifier('loading', false)
      })
    })
  }

  update(newState) {
    if (this.state === newState) return

    this.build(newState)
    this.state = newState
  }

  build(state) {
    if (this.list.toggleModifier('is-popup', state.dropdownMenuShown)) {
      this._createMenu()
    } else {
      this._destroyMenu()
    }

    if (this.state.elevation !== state.elevation) {
      this.element('dropdown-height').element.textContent = state.elevation
      this.element('dropdown-prefix').element.textContent = state.dropdownPrefix
    }

    this.elements('mountain').forEach(mountain => {
      const { element } = mountain
      element.src = state.image
    })

    document.documentElement.style.setProperty('--active-elevation-color', state.color)
  }

  _createMenu() {
    this.popper = createPopper(this.dropdown.element, this.list.element, {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'flip',
          enabled: false
        },
        {
          name: 'offset',
          options: {
            offset: [0, -16]
          }
        },
        {
          name: 'eventListeners',
          options: {
            resize: false,
            scroll: false
          }
        }
      ]
    })

    requestAnimationFrame(() => {
      document.body.addEventListener('click', this.clickListener)
    })
  }

  _destroyMenu() {
    if (this.popper != null) this.popper.destroy()

    this.poper = null
  }

  _outsideClickListenerOnce(event) {
    if (this.list.element.contains(event.target)) return

    document.body.removeEventListener('click', this.clickListener)

    this.update(this.state.copyWith({ dropdownMenuShown: false }))
  }

  async linkChanged(link) {
    const { elevationGroup, elevationLevel } = link.dataset

    let dropdownPrefix = ''
    const prefix = link.querySelector(`.${this.elementClassName('prefix')}`)
    if (prefix != null) dropdownPrefix = prefix.dataset.elevationPrefix

    // in case it was converted or something
    const formattedElevation = Number(link.querySelector('.height').textContent)

    try {
      await window.changeElevation(elevationLevel)
    } catch (e) {
      airbrake.notify(e)
      return
    }

    this.update(
      this.state.copyWith({
        elevation: formattedElevation,
        image: images[elevationGroup],
        color: colors[elevationGroup],
        dropdownPrefix,
        dropdownMenuShown: false
      })
    )
  }
}
