// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
export default function elementRemovePolyfill() {
  const arr = [window.Element, window.CharacterData, window.DocumentType]
  const args = []

  arr.forEach(item => {
    if (item) {
      args.push(item.prototype)
    }
  })

  args.forEach(item => {
    if (Object.prototype.hasOwnProperty.call(item, 'remove')) {
      return
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode) this.parentNode.removeChild(this)
        else {
          document.body.appendChild(this)
          this.parentNode.removeChild(this)
        }
      }
    })
  })
}
