export class ScrollButtonState {
  /**
   * Creates an instance of ScrollButtonState.
   * @param {object} settings
   * @param {boolean} settings.isHidden
   * @memberof ScrollButtonState
   */
  constructor({ isHidden }) {
    this.isHidden = isHidden
  }

  /**
   * @param {object} settings
   * @param {boolean} settings.isHidden
   * @memberof ScrollButtonState
   */
  copyWith({ isHidden }) {
    return new ScrollButtonState({
      isHidden: isHidden === undefined ? this.isHidden : isHidden
    })
  }
}
