import remove from './remove'
import dataset from './dataset'
import classList from './classlist'
import replaceWith from './replace-with'

export default function () {
  remove()
  dataset()
  classList()
  replaceWith()
}
