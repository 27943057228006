// https://developer.mozilla.org/en-US/docs/Web/API/Element/classList#Polyfill
/*
eslint-disable
no-irregular-whitespace,
no-param-reassign,
no-extend-native,
no-global-assign,
no-plusplus,
func-names,
no-void
*/
function patchDOMTokenList(DOMTokenListProto, testClass) {
  const wsRE = /[\11\12\14\15\40]/
  const checkIfValidClassListEntry = function (O, V) {
    if (V === '')
      throw new DOMException(
        `Failed to execute '${O}' on 'DOMTokenList': The token provided must not be empty.`
      )

    const wsIndex = V.search(wsRE) !== -1
    if (wsIndex)
      throw new DOMException(
        `Failed to execute '${O}' on 'DOMTokenList': ` +
          `The token provided ('${V[wsIndex]}') contains HTML space characters, which are not valid in tokens.`
      )
  }

  if (!DOMTokenListProto.item)
    DOMTokenListProto.item = function (i) {
      function NullCheck(n) {
        return n === void 0 ? null : n
      }
      return NullCheck(this[i])
    }

  /* eslint-disable prefer-rest-params, no-sequences */
  if (!DOMTokenListProto.toggle || testClass.toggle('a', 0) !== false)
    DOMTokenListProto.toggle = function (val) {
      if (arguments.length > 1) return this[arguments[1] ? 'add' : 'remove'](val), !!arguments[1]
      const oldValue = this.value
      return this.remove(oldValue), oldValue === this.value && (this.add(val), true) /* || false */
    }

  if (!DOMTokenListProto.replace || typeof testClass.replace('a', 'b') !== 'boolean')
    DOMTokenListProto.replace = function (oldToken, newToken) {
      checkIfValidClassListEntry('replace', oldToken)
      checkIfValidClassListEntry('replace', newToken)
      const oldValue = this.value
      return this.remove(oldToken), this.value !== oldValue && (this.add(newToken), true)
    }

  if (!DOMTokenListProto.contains)
    DOMTokenListProto.contains = function (value) {
      for (let i = 0, Len = this.length; i !== Len; ++i) if (this[i] === value) return true
      return false
    }

  if (!DOMTokenListProto.forEach)
    DOMTokenListProto.forEach = function (f) {
      if (arguments.length === 1)
        for (let i = 0, Len = this.length; i !== Len; ++i) f(this[i], i, this)
      else
        for (let i = 0, Len = this.length, tArg = arguments[1]; i !== Len; ++i)
          f.call(tArg, this[i], i, this)
    }

  if (!DOMTokenListProto.entries)
    DOMTokenListProto.entries = function () {
      const that = this
      let nextIndex = 0
      return {
        next() {
          return nextIndex < that.length
            ? { value: [nextIndex, that[nextIndex++]], done: false }
            : { done: true }
        }
      }
    }

  if (!DOMTokenListProto.values)
    DOMTokenListProto.values = function () {
      const that = this
      let nextIndex = 0
      return {
        next() {
          return nextIndex < that.length
            ? { value: that[nextIndex++], done: false }
            : { done: true }
        }
      }
    }

  if (!DOMTokenListProto.keys)
    DOMTokenListProto.keys = function () {
      let nextIndex = 0
      const that = this
      return {
        next() {
          return nextIndex < that.length ? { value: nextIndex++, done: false } : { done: true }
        }
      }
    }
}

export default function classListPolyfill() {
  // Patch in unsupported methods in DOMTokenList
  patchDOMTokenList(window.DOMTokenList.prototype, window.document.createElement('div').classList)
}
