const getRect = ({ el, options: { bottomSpacing = 0, topSpacing = 0 } }) => ({
  width: el.offsetWidth || el.clientWidth || 0,
  height: el.offsetHeight || el.clientHeight || 0,
  topSpacing,
  bottomSpacing
})

const createPlaceholder = ({ tagName = 'div', width = 0, height = 0, classes = '' }) => {
  const placeholder = document.createElement(tagName)
  placeholder.style.width = `${width}px`
  placeholder.style.height = `${height}px`
  placeholder.className = classes

  return placeholder
}

const calculateStopper = ({ stopper = null, elRect }) => {
  if (!stopper) return null

  const el = document.querySelector(stopper)
  if (!el) return null

  return el.offsetTop - elRect.height - elRect.topSpacing
}

const stickyScroll = ({
  el,
  classes,
  rect,
  startPoint,
  stopPoint,
  placeholder,
  zIndex,
  mediaQuery
}) => {
  const { style } = el
  let isSticky = false

  return function handler() {
    if (mediaQuery && !matchMedia(`(${mediaQuery})`).matches) return

    /* eslint-disable-next-line compat/compat */
    const windowOffset = window.scrollY || window.pageYOffset

    if (startPoint < windowOffset) {
      isSticky = true

      if (classes) {
        el.classList.add(classes)
      }
      el.parentNode.insertBefore(placeholder, el.nextSibling)

      let top = rect.topSpacing

      if (stopPoint && stopPoint < windowOffset) {
        top = stopPoint - windowOffset + rect.topSpacing
      }

      if (rect.bottomSpacing) top -= rect.bottomSpacing

      style.position = 'fixed'
      style.top = `${top}px`

      if (zIndex) style.zIndex = zIndex
    }

    if (startPoint > windowOffset && isSticky) {
      style.position = 'static'
      placeholder.parentNode.removeChild(placeholder)
      isSticky = false

      if (classes) {
        el.classList.remove(classes)
      }

      if (zIndex) style.zIndex = 'initial'
    }
  }
}

const Sticky = (selector, options) => {
  const el = document.querySelector(selector)
  if (!el) return

  const rect = getRect({ el, options })

  const startPoint = el.offsetTop - rect.topSpacing || 0
  const stopPoint = calculateStopper({
    stopper: options.stopper,
    elRect: rect
  })

  const placeholder = createPlaceholder({
    tagName: el.tagName,
    width: rect.width,
    height: rect.height,
    classes: options.placeholderClassName
  })

  window.addEventListener(
    'scroll',
    stickyScroll({
      el,
      placeholder,
      startPoint,
      stopPoint,
      rect,
      classes: options.classes,
      zIndex: options.zIndex,
      mediaQuery: options.mediaQuery
    })
  )
}

export default function () {
  const topTabs = document.querySelector('#navs')
  const somethingOffset = topTabs && (topTabs.offsetHeight || topTabs.clientHeight)
  Sticky('.something-useful--js', {
    stopper: '#sfooter',
    /* add - 30 because the #navs sticky header has -45px top property */
    topSpacing: (somethingOffset || 0) - 30
  })
}
